export const myMixin = {
  data() {
    return {

    }
  },
  methods: {
    getStartDate() {
      let val = new Date()
      val.setMonth(val.getMonth()-1)
      var y = val.getFullYear();
      var m = val.getMonth()+1;
      var d = val.getDate();
      return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d)
    },
    getDateStr(num) {
      let val = new Date()
      val.setDate(val.getDate() - num)
      var y = val.getFullYear();
      var m = val.getMonth()+1;
      var d = val.getDate();
      return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d)
    },
    // 格式化时间
    format(d) {
      return d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + '-' + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate())
    }
  }
}