<template>
  <div class="recharge-record-page">
    <div class="header-box">
      <span class="recharge-date">消耗日期</span>
      <el-date-picker class="btn-date-picker" v-model="time" type="daterange" :editable="false" :clearable="false"
        align="center" range-separator="-" placeholder="选择日期范围"></el-date-picker>
      <el-select v-model="params.type" placeholder="链接选择" style="margin-right: 10px">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="params.status" placeholder="选择状态">
        <el-option v-for="item in statuslist" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-button @click="orderList">查询</el-button>
    </div>
    <div class="table-box">
      <el-table :data="tableData" v-loading="loading" border style="width: 100%" :height="getStyle"
        :header-cell-style="{ background: '#eef1f6' }">
        <el-table-column type="index" label="序号" width="50" align="center">
        </el-table-column>
        <el-table-column prop="orderNo" label="订单号" align="center" width="100"></el-table-column>
        <el-table-column prop="payDate" label="日期" align="center" width="180"></el-table-column>
        <el-table-column prop="type" label="类型" align="center" width="100">
          <template slot-scope="scope">
            {{ getType(scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column prop="description" label="消耗" align="center" width="190">
        </el-table-column>
        <el-table-column label="内容" align="center">
          <template slot-scope="scope" v-if="scope.row.orderStatus != '05'">
            <div class="align-left" v-if="scope.row.type == 'LINK'">
              <span class="link-url" :ref="scope.row.orderNo">{{
              scope.row.goodUrl
              }}</span>
              <span class="table-oper-txt" @click="tapCopyLink(scope.row.orderNo)">复制链接</span>
            </div>
            <div class="align-left" v-else>
              {{ getContent(scope.row) }}
            </div>
          </template>
          <span v-else></span>
        </el-table-column>
        <!-- <el-table-column
          prop="goodUrl"
          label="链接"
          align="left"
        >
          <template slot-scope="scope">
            <span class="link-url" ref="link">{{scope.row.goodUrl}}</span>
            <span class="table-oper-txt" @click="tapCopyLink(scope.row.goodUrl)">复制链接</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          prop="payCoinNum"
          label="金币情况"
          align="center"
          width="110"
        ></el-table-column> -->
        <el-table-column label="操作" align="center" width="175">
          <template slot-scope="scope">
            <!-- <span
              class="table-oper-txt"
              @click="opergetBackDV(scope.row)"
              v-if="
                (scope.row.orderStatus === '02' || scope.row.orderStatus === '06') && getTime(scope.row)
              "
              >申诉</span
            >
            <span v-else>{{ getOrderStatus(scope.row.orderStatus) }}</span> -->
            <div>
              <!-- 申诉中 -->
              <el-popover v-if="scope.row.orderStatus === '04'" placement="bottom" trigger="hover" width="300"
                content="申诉中，如有疑问，请联系右侧客户经理">
                <span class="table-disabled" slot="reference">申诉中</span>
              </el-popover>
              <!-- 申诉成功 -->
              <el-popover v-else-if="scope.row.orderStatus === '05'" placement="bottom" trigger="hover" width="300"
                content="申诉成功，已返回金币，请注意查收，如有疑问请联系右侧客户经理">
                <span class="table-disabled" slot="reference">申诉成功</span>
              </el-popover>

              <!-- 申诉失败 -->
              <el-popover v-else-if="scope.row.orderStatus === '06'" placement="bottom" trigger="hover" width="300"
                content="申诉驳回，如有疑问，请联系右侧客户经理">
                <span class="table-disabled" slot="reference">申诉驳回</span>
              </el-popover>

              <!-- 购买链接2小时之内的状态 -->
              <span class="table-oper-txt" @click="opergetBackDV(scope.row)"
                v-else-if="getTime(scope.row) && scope.row.type !== 'SEARCH'">申诉</span>

              <!-- 查询不可申诉 -->
              <el-popover v-else-if="scope.row.type === 'SEARCH'" placement="bottom" trigger="hover" width="300"
                content="查询无法申诉，如有疑问，请联系右侧客户经理">
                <span class="table-disabled" slot="reference">申诉</span>
              </el-popover>

              <el-popover v-else placement="bottom" trigger="hover" width="300" content="超过24小时，无法申诉，如有疑问，请联系右侧客户经理">
                <span class="table-disabled" slot="reference">申诉</span>
              </el-popover>
              <!-- <span class="history" @click="historyes(scope.row.orderNo)">历史记录</span> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="1"
          :page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="申诉" :visible.sync="getBackDV" width="600px" :before-close="closegetBackDV" class="get-back-DV"
      :close-on-click-modal="false">
      <el-form ref="getBackForm" :model="getBackForm" class="get-back-form" label-width="80px" :rules="rules"
        v-loading="imgLoading">
        <el-form-item label="申诉原因" prop="reasons">
          <el-checkbox-group v-model="getBackForm.reasons">
            <el-checkbox :label="item" name="type" v-for="(item, index) in reason" :key="index">{{ item }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="问题描述" prop="details">
          <el-input type="textarea" v-model="getBackForm.details" placeholder="请输入" maxlength="100" show-word-limit>
          </el-input>
        </el-form-item>
        <el-form-item label="凭证信息" prop="complaintUrls">
          <div class="img-list">
            <template v-if="getBackForm.complaintUrls.length > 0">
              <div v-for="(item, index) in getBackForm.complaintUrls" :key="index" class="avatar">
                <img :src="item" />
                <i class="el-icon-error" @click="tapDeleteImg(index)"></i>
              </div>
            </template>
            <el-upload class="avatar-uploader" action="/api/upload/image" :show-file-list="false"
              :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :data="{ type: 'complaint' }">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="format-type">仅支持jpg/png格式，文件大小5M以内</div>
        </el-form-item>
        <el-form-item label="验证码" class="code" prop="captcha">
          <el-input v-model="getBackForm.captcha" placeholder="请输入"></el-input>
          <div class="img-code">
            <img :src="imgCode" @click="tapCutImg" />
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closegetBackDV" class="cancel-btn">取 消</el-button>
        <el-button @click="tapGetBack" class="get-back-bnt">申诉</el-button>
      </span>
    </el-dialog>
    <el-dialog title="快照" :visible.sync="historyDV" width="1200px" :before-close="closeHistoryDV" class="history-DV">
      <div class="table-DV">
        <el-table :data="rowTableData" border style="width: 100%">
          <el-table-column align="center" prop="showImageSrc" label="商品图片">
            <template slot-scope="scope">
              <el-popover placement="right-start" width="306" height="306" trigger="hover">
                <img :src="scope.row.showImageSrc" class="popver-img" v-if="scope.row.showImageSrc" />
                <!-- <img src="../../assets/default.png" class="popver-img" v-else /> -->
                <img :src="scope.row.showImageSrc" slot="reference" class="look-img" v-if="scope.row.showImageSrc" />
                <!-- <img
                  src="../../assets/default.png"
                  slot="reference"
                  class="look-img"
                  v-else
                /> -->
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="stars" label="用户评分">
          </el-table-column>
          <el-table-column align="center" prop="beginSaleDate" label="上架时间" width="105"></el-table-column>
          <el-table-column align="center" prop="unavailableSaleDate" label="最后评论时间" width="105"></el-table-column>
          <el-table-column align="center" prop="level2CategoryName" label="类目"></el-table-column>
          <el-table-column align="center" prop="showBrand" label="品牌"></el-table-column>
          <el-table-column align="center" prop="showBrandType" label="品牌类型"></el-table-column>
          <el-table-column align="center" prop="totalRating" label="评价数"></el-table-column>
          <el-table-column align="center" prop="showTopStars" label="首页评分"></el-table-column>
          <el-table-column align="center" prop="customerReviews" width="120" label="评价占比">
            <template slot-scope="scope">
              <div class="progress-box">
                <div class="progress">
                  <div class="size">{{ getCustomerReviews(scope.row, 5) }}</div>
                  <div :style="{ height: getHeight(scope.row, 5) }"></div>
                  <div class="size">5星</div>
                </div>
                <div class="progress">
                  <div class="size">{{ getCustomerReviews(scope.row, 4) }}</div>
                  <div :style="{ height: getHeight(scope.row, 4) }">1</div>
                  <div class="size">4星</div>
                </div>
                <div class="progress">
                  <div class="size">{{ getCustomerReviews(scope.row, 3) }}</div>
                  <div :style="{ height: getHeight(scope.row, 3) }">1</div>
                  <div class="size">3星</div>
                </div>
                <div class="progress">
                  <div class="size">{{ getCustomerReviews(scope.row, 2) }}</div>
                  <div :style="{ height: getHeight(scope.row, 2) }">1</div>
                  <div class="size">2星</div>
                </div>
                <div class="progress">
                  <div class="size">{{ getCustomerReviews(scope.row, 1) }}</div>
                  <div :style="{ height: getHeight(scope.row, 1) }">1</div>
                  <div class="size">1星</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="reviewsImagesNum" label="链接图片数量" width="105"></el-table-column>
          <el-table-column align="center" prop="gradeScore" label="快现行评分"></el-table-column>
          <el-table-column align="center" prop="price" label="金币"></el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeHistoryDV">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  orderList,
  queryComplaintReason,
  complaint,
  getQuery,
} from "@/api/user.js";
import { myMixin } from "@/mixins/mixin.js";
export default {
  name: "useRecord",
  components: {},
  props: {},
  mixins: [myMixin],
  data() {
    return {
      historyDV: false,
      rowTableData: [
        {
          beginSaleDate: "2016-11-21",
          criticalNumAfterTop5: 0,
          criticalNumBeforeTop3: 0,
          customerReviews: {
            totalView: 24,
            reviewStar1Percent: "0%",
            reviewStar2Percent: "0%",
            reviewStar3Percent: "6%",
            reviewStar4Percent: "15%",
            reviewStar5Percent: "79%",
          },
          goodId: 14686982,
          gradeScore: 0,
          price: 90,
          reviewsImagesNum: 0,
          showBrandType: "无品牌",
          showTopStars: "5-5-5-5-4-5-5-5",
          stars: 4.7,
          totalRating: 24,
          totalReview: 24,
          unavailableSaleDate: "2019-07-13",
        },
      ],
      params: {
        pageNo: 1,
        pageSize: 10,
        beginDate: "",
        endDate: "",
        type: "",
        status: "0",
      },
      time: ["", ""],
      tableData: [],
      total: 0,
      getBackDV: false,
      getBackForm: {
        reasons: [],
        reason: "",
        details: "",
        complaintUrls: [],
        orderNo: "",
        captcha: "",
      },
      reason: [],
      imgCode: "/api/captcha/get?type=bindMobile",
      loading: false,
      imgLoading: false,
      rules: {
        reasons: [
          {
            type: "array",
            required: true,
            message: "至少选择一个原因",
            trigger: "change",
          },
        ],
        details: [
          { required: true, message: "请填写问题描述", trigger: "blur" },
        ],
        complaintUrls: [
          {
            type: "array",
            required: true,
            message: "至少上传一张图片",
            trigger: "change",
          },
        ],
        captcha: [
          { required: true, message: "请输入图片验证码", trigger: "blur" },
        ],
      },
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0",
          label: "链接选择",
        },
        {
          value: "1",
          label: "关键词查询",
        },
      ],
      statuslist: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "02",
          label: "正常使用",
        },
        {
          value: "04",
          label: "申诉中",
        },
        {
          value: "05",
          label: "申诉成功",
        },
        {
          value: "06",
          label: "申诉失败",
        },
      ],
    };
  },
  computed: {
    getStyle() {
      if (this.tableData.length > 0) {
        return "100%";
      } else {
        return "auto";
      }
    },
  },
  watch: {},
  created() {
    this.time = [new Date(this.getStartDate()), new Date()];
    this.orderList();
  },
  methods: {
    closeHistoryDV() {
      this.historyDV = false;
    },
    async historyes(orderNo) {
      let res = await getQuery({ orderNo: orderNo });
      // console.log(res)
      this.rowTableData = res.data.data;
      this.historyDV = true;
    },
    // 获取使用记录信息
    orderList() {
      this.loading = true;
      this.params.beginDate = this.format(this.time[0]);
      this.params.endDate = this.format(this.time[1]);
      orderList(this.params).then((res) => {
        this.loading = false;
        if (res.success) {
          this.tableData = res.data.data || [];
          this.total = res.data.totalRecord;
        }
      });
    },
    getOrderStatus(val) {
      const obj = {
        "00": "待付款",
        "01": "付款中",
        "02": "付款成功",
        "03": "付款失败",
        "04": "申诉中",
        "05": "申诉成功",
        "06": "申诉失败",
      };
      return obj[val];
    },
    // 获取订单申诉原因列表
    queryComplaintReason() {
      queryComplaintReason().then((res) => {
        this.reason = res.data;
        this.getBackDV = true;
      });
    },
    getType(val) {
      const obj = {
        LINK: "购买链接",
        SEARCH: "关键词查询",
        WITHDRAW: "金币提现",
      };
      return obj[val];
    },
    // getExpend(val) {
    //   let txt = ''
    //   switch (val.type) {
    //     case 'LINK':
    //       txt = val.goodUrl
    //       break
    //     case 'SEARCH':
    //       txt = val.searchRequest.searchData
    //       break
    //   }
    //   return txt
    // },
    getContent(val) {
      let txt = "";
      const { brandFlag, level1CategoryName, level2CategoryName, searchData } =
        val.searchRequest;
      let brand;
      let categoryName = "";
      switch (val.type) {
        case "LINK":
          txt = val.goodUrl;
          break;
        case "SEARCH":
          if (brandFlag == "all") {
            brand = "全部";
          } else if (brandFlag == "true") {
            brand = "品牌";
          } else {
            brand = "无品牌";
          }
          categoryName = level1CategoryName
            ? categoryName + level1CategoryName
            : "";
          categoryName = level2CategoryName
            ? categoryName + "，" + level2CategoryName
            : categoryName;
          if (
            (!level1CategoryName && !level2CategoryName) ||
            level1CategoryName == "All"
          ) {
            categoryName = "全部";
          }
          txt = brand + " / " + categoryName + " / " + searchData;
          break;
      }
      return txt;
    },
    // 获取图形验证码
    tapCutImg() {
      this.imgCode = "/api/captcha/get?type=bindMobile" + +new Date();
    },
    // 打开弹窗
    opergetBackDV(row) {
      this.getBackForm.orderNo = row.orderNo;
      this.queryComplaintReason();
    },
    // 关闭弹窗
    closegetBackDV() {
      this.getBackDV = false;
      this.$refs["getBackForm"].resetFields();
      this.getBackForm = {
        reasons: [],
        reason: [],
        details: "",
        complaintUrls: [],
        orderNo: "",
        captcha: "",
      };
      this.tapCutImg();
    },
    // 申诉
    tapGetBack() {
      this.$refs["getBackForm"].validate((valid) => {
        if (valid) {
          this.getBackForm.reason = this.getBackForm.reasons.join(",");
          complaint(this.getBackForm).then((res) => {
            if (res.success) {
              this.$message.success("提交申诉成功");
              this.closegetBackDV();
              this.orderList();
            } else {
              this.tapCutImg();
            }
          });
        } else {
          return false;
        }
      });
    },
    // 获取图片
    handleAvatarSuccess(res) {
      // console.log(file);
      this.imgLoading = false;
      if (res.success) {
        // URL.createObjectURL(file.raw)
        this.getBackForm.complaintUrls.push(res.data);
        this.$message.success("图片上传成功");
      }
    },
    // 验证图片信息
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPG) this.$message.error("上传头像图片只能是JPG和PNG格式!");
      if (!isLt5M) this.$message.error("上传头像图片大小不能超过 5MB!");
      if (isJPG && isLt5M) {
        this.imgLoading = true;
      }
      return isJPG && isLt5M;
    },
    // 点击删除图片
    tapDeleteImg(index) {
      this.$confirm("您确认要删除该张图片吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$message.closeAll();
        this.getBackForm.complaintUrls.splice(index, 1);
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    },
    tapCopyLink(id) {
      this.$message.closeAll();
      window.getSelection().selectAllChildren(this.$refs[id]);
      document.execCommand("copy");
      this.$message.success("复制成功");
    },
    getTime(row) {
      if ((new Date().getTime() - new Date(row.payDate).getTime()) > 1000 * 60 * 60 * 24) {
        return false;
      } else {
        return true;
      }
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.orderList();
    },
    handleCurrentChange(val) {
      this.params.pageNo = val;
      this.orderList();
    },
  },
};
</script>

<style scoped lang="scss">
.recharge-record-page {
  position: relative;
  width: 1010px;
  height: 100%;
  padding: 20px;
  box-shadow: 0px 2px 12px 0px #edecf6;
  border-radius: 4px;

  .header-box {
    display: flex;
    align-items: center;

    .recharge-date {
      margin-right: 22px;
    }

    .el-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 66px;
      height: 30px;
      background: linear-gradient(#3777ff, #0e39ff);
      border-color: #3777ff;
      color: #fff;
      margin: 0 0 0 22px;
    }

    ::v-deep.el-input__inner {
      width: 180px;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
    }

    ::v-deep.el-icon-arrow-up {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  ::v-deep.el-date-editor {
    width: 200px !important;
    height: 30px;
    line-height: 30px !important;
    font-size: 12px !important;
    margin-right: 18px;

    .el-range-input {
      font-size: 12px;
    }

    .el-icon-date {
      margin-top: -8px;

      &::before {
        margin-right: -360px;
      }
    }

    .el-range__icon {
      margin-left: -22px;
    }

    .el-range-separator {
      line-height: 22px;
    }
  }

  .table-box {
    height: calc(100% - 100px);
    margin-top: 20px;

    ::v-deep.has-gutter {
      color: #373e4f;
    }

    .link-url {
      margin-right: 12px;
    }

    .table-oper-txt {
      cursor: pointer;
      color: #4f94f9;
    }

    .table-disabled {
      font-size: 14px;
      color: #a3aab7;
      cursor: no-drop;
    }

    .block {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .align-left {
      text-align: left !important;
    }
  }
}

::v-deep.get-back-DV {
  .get-back-form {
    .el-form-item {
      margin-bottom: 18px;
    }

    .el-form-item__error {
      left: 28px;
    }

    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      padding: 0 32px 0 30px;
    }

    .el-textarea__inner {
      width: 420px;
      margin-left: 30px;
    }

    .el-input__count {
      background: transparent;
      bottom: -10px;
      right: 45px;
    }

    .code {
      position: relative;

      .el-input__inner {
        width: 348px;
        height: 38px;
        line-height: 38px;
        margin-left: 30px;
      }

      .img-code {
        position: absolute;
        top: 3px;
        right: 30px;
        width: 72px;
        height: 36px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .img-list {
    display: flex;
    padding-left: 30px;

    .avatar {
      position: relative;
      width: 60px;
      height: 60px;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
      }

      i {
        position: absolute;
        /* bottom: 0;
        right: 0; */
        cursor: pointer;
      }

      i.el-icon-error {
        color: #ff455b;
        top: -8px;
        right: -8px;
        font-size: 16px;
      }
    }
  }

  .format-type {
    height: 30px;
    font-size: 14px;
    margin: -20px 0 0 28px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
  }

  .el-upload__tip {
    margin: -20px 0 0 25px;
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
    margin-top: -25px;

    .el-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 46px;
      font-size: 16px;
      color: #444b5b;
    }

    .get-back-bnt {
      color: #fff;
      background: linear-gradient(0deg, #0831ff, #3777ff);
      border-color: #3777ff;
    }
  }
}

.history {
  cursor: pointer;
}
</style>
